




import Vue from 'vue';
import Component from 'vue-class-component';

import logger from '@/logger';
import FlashMessage from '@/components/molecules/FlashMessage.vue';
import { displayInfo, displayWait } from '@/helpers/message';
import { resetAuth } from '@/helpers/auth';

@Component({ components: { FlashMessage } })
export default class Logout extends Vue {

  async mounted(): Promise<void> {
    this.logout();
  }

  logout(): void {
    logger.info('Logging out...');
    displayWait(this.$gettext('Processing...'));

    const withUrlParam = false;
    resetAuth(withUrlParam);

    logger.info('Logged out');
    displayInfo(this.$gettext('Logged out'));
  }
}
